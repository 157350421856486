// .app-layout {
//   // display: grid;
//   grid-template-rows: auto 1fr auto;
//   min-height: 100vh;
// }

main {
  padding: 1rem;
}

.main-layout {
  display: flex;
  margin-top: 100px;
  height: calc(100vh - 145px);
  background-color: rgba(251, 251, 251, 1);
}

.MuiDrawer-paper {
  position: unset !important;
}
.panelModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #003087;
}
.panelDiv {
  background-color: #b6c3db;
  border-radius: 10px;
  padding: 12px;
  text-align: center;
}
.panelForm {
  width: 72vw;
  max-height: 90vh;
  overflow-y: auto;
  padding: 15px;
}
.inputLabel {
  line-height: 0.91em !important;
}
.linkText {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
.attributeDiv {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.pointerLink {
  cursor: pointer;
}
.pointerLink:hover {
  color: #9d2235;
}

.blueLinkText {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  color: #003087;
}

.linkRegularText {
  cursor: pointer;
  color: #003087;
}
.chipStyle {
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  padding: "0 2px";
  margin: "0px 14px 6px -8px";
  background-color: rgba(0, 48, 135, 0.15);
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 14px;
  color: #003087;
  cursor: pointer;
}
