.treeModal {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #003087;
}
.treeForm {
  width: 19vw;
  max-height: 90vh;
  overflow-y: auto;
  padding: 15px;
}
